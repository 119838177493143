/**
 * Save the state of a component for the duration of a session.
 * Sessions lasts until tab or browser is closed.
 */
export default class ComponentState {
  /**
     * @param {HTMLElement} el The element to bind this state to
     * @param {*} defaultValue A default value to set if there is no saved state.
     */
  constructor(el, defaultValue) {
    this.changeHandler = [];
    this.id = el.getAttribute('id');
        
    if ((this.id) && (defaultValue !== undefined)) {
      const value = this.get(this.id);
      if (value === null) {
        this.set(defaultValue);
      }
    }
  }
    
  /*
     * Set the component's state.
     * @param {*} value The value to save to this component's state.
     */
  set(value) {
    if (this.id) {
      sessionStorage.setItem(this.id, JSON.stringify(value));
            
      this.changeHandler.forEach((handler) => {
        if (typeof handler === 'function') {
          handler(value);
        }
      });
    }
  }
    
  /**
     * Get the component's state.
     */
  get() {
    if (this.id) {
      return JSON.parse(sessionStorage.getItem(this.id));
    }
    return null;
  }
    
  /**
     * Bind a function to whenever the state changes. This allow the state
     * to be changed by other components and reflect within the component.
     * @param {function} handler Function to execute after "set" method is called.
     */
  onChange(handler) {
    if ((this.id) && (typeof handler === 'function')) {
      this.changeHandler.push(handler);
    }
  }
}
