import detectMobile from '../globals/detectMobile';
import debounce from '../globals/debounce';

export default class NavFull {
  constructor(navBar) {
    this.navFullContainer = navBar;
    this.parentContainer = this.navFullContainer.closest('.sxm-full-nav-container');
    this.navItem = [...navBar.getElementsByClassName('top-nav-item')];
    this.searchNavItems = [...navBar.querySelectorAll('.top-nav-item .top-nav-link.nav-search-link')];
    this.navLinks = [...navBar.querySelectorAll('.top-nav-link.has-more, .top-nav-link.has-self-drop')];
    this.dropNavs = [...navBar.getElementsByClassName('drop-nav')];
    this.topNavLinks = [...navBar.getElementsByClassName('top-nav-link')];
    this.closeNav = [...navBar.getElementsByClassName('close-nav')];
    this.mainNav = document.getElementById('mainNav');
    this.hamburger = document.getElementById('navHamburger');
    // Temporary change from 1024 to fix logo clipping
    this.breakpointSize = 1100;
    this.searchIsOpen = false;
    this.mobileSearchIsOpen = false;
    this.mobileUserAccIsOpen = false;
    this.mobileSearch = navBar.querySelector('.search.predictive-search.mobile');
    this.mobileUserAccBtn = navBar.querySelector('.mobile-user-login');
    this.mobileSearchBtn = navBar.querySelector('.mobile-search-button');
    this.eventSearchOpen = new CustomEvent('search-opened');
    this.eventCloseSearchNav = new CustomEvent('search-closed');
    this.mobileNav = document.querySelector('.mobile-secondary-nav');

    if (this.mobileNav) {
      this.mobileNav.addEventListener('click', this.closeMobileSearch);
    }

    const desktopSearchEl = navBar.querySelector('#NavSearch .search');
    const mobileSearchEl = navBar.querySelector('#MobileNavSearch');

    if (desktopSearchEl) {
      this.desktopSearchBar = desktopSearchEl._sxm.SearchBar;
    }

    if (mobileSearchEl) {
      this.mobileSearchBar = mobileSearchEl._sxm.SearchBar;
    }

    if (window.location.hostname.indexOf('cms-author') === -1) {
      const mobileLink = navBar.querySelector('.mobile-nav-title a');
      if (mobileLink.getAttribute('href') === '/') {
        mobileLink.setAttribute('href', 'https://www.siriusxm.com');
      }

      const desktopLink = navBar.querySelector('.logo-col a');
      if (desktopLink.getAttribute('href') === '/') {
        desktopLink.setAttribute('href', 'https://www.siriusxm.com');
      }
    }

    [...this.navFullContainer.querySelectorAll('img')].forEach((img) => {
      img._sxm.LazyImage.loadImage();
    });

    // Instantiate search for Desktop
    this.searchContainer = document.getElementById('NavSearch');
    this.hasSearch = !!this.searchContainer;

    if (this.hasSearch) {
      this.desktopSearchInput = this.searchContainer.querySelector('.search-bar');
      this.mobileSearchContainer = document.getElementById('MobileNavSearch');
    }

    this.navItem.forEach(this.enableNavEvents);

    // add click events for each of the Top level nav items
    this.navLinks.forEach((el) => {
      el.addEventListener('click', this.clickNavLink);
    });

    // Events to close sub menus by clicking anywhere on the screen
    document.addEventListener('click', this.documentCloseSubMenu);
    document.addEventListener('touchstart', this.documentCloseSubMenu);
    document.addEventListener('touchend', this.documentCloseSubMenu);

    // Click Event for Hamburger Menu
    this.hamburger.addEventListener('click', this.openMobileSliderMenu);

    // Mouseleave Event for subnav
    this.navFullContainer.addEventListener('mouseleave', this.mouseLeavehandler);

    // Loop through all mobile nav close buttons and close the mobile nav
    this.closeNav.forEach(el => {
      el.addEventListener('click', this.closeMobileNav);
    });

    if (this.hasSearch) {
      // toggle search
      this.searchNavItems.forEach(searchLink => {
        searchLink.addEventListener('click', this.toggleSearchDisplay);
      });

      // Search button Mobile
      this.mobileSearchBtn.addEventListener('click', this.toggleMobileSearchMenu);

      // Listen for populated predictive search results
      document.addEventListener('predictive-results-populated', this.resizePredictiveOnNav);
      document.addEventListener('predictive-results-closed', () => setTimeout(this.showPredictiveContainer, 100));
    }

    // resize events
    window.addEventListener('resize', this.navWindowResizeHandler);

    // User account button mobile
    if (this.mobileUserAccBtn) {
      this.mobileUserAccBtn.addEventListener('click', this.toggleMobileUserAccMenu);
    }

    this.navFullContainer.addEventListener('keydown', this.escapeHandler);
  }

  /**
   * Close opened nav items on escape
   * @param {object} event
   */
  escapeHandler = (event) => {
    const { keyCode, target } = event;

    if (keyCode === 27) {
      if (target.classList.contains('nav-search-link')) {
        this.closeDektopSearch(target);
      } else {
        this.closeSubNav();
      }
    }
  }

  /**
   * Dynamically get the nav's bounding rectangle
  */
  getNavRect() {
    return this.navFullContainer.getBoundingClientRect();
  }

  /**
   * Toggle mobile search
   * @param {object} event event data
   * @returns {void}
   */
  toggleMobileSearchMenu = event => {
    const { currentTarget } = event;
    if (this.mobileSearchIsOpen) {
      this.closeMobileSearch();
      currentTarget.setAttribute('aria-label', 'Search');
      currentTarget.setAttribute('title', 'Search');
    } else {
      this.openMobileSearch();
      this.closeMobileUserAccMenu();
      currentTarget.setAttribute('aria-label', 'Close search');
      currentTarget.setAttribute('title', 'Close search');

      if (this.mobileSearchBar) {
        this.mobileSearchBar.focus();
      }
    }
  }

  /**
   * Toggles the user account menu on mobile
   * @param {object} event event data
   * @returns {void}
   */
  toggleMobileUserAccMenu = event => {
    if (this.mobileUserAccIsOpen) {
      this.closeMobileUserAccMenu();
    } else {
      this.openMobileUserAccMenu();
      this.closeMobileSearch();
    }
  }

  openMobileSearch = () => {
    if (!this.mobileSearch.classList.contains('active')) {
      this.mobileSearch.classList.add('active');
    }

    if (!this.mobileSearchBtn.classList.contains('open')) {
      this.mobileSearchBtn.classList.toggle('open');
    }

    this.mobileSearchIsOpen = true;
  }

  closeMobileSearch = () => {
    if (this.hasSearch) {
      if (this.mobileSearch.classList.contains('active')) {
        this.mobileSearch.classList.remove('active');
      }

      if (this.mobileSearchBtn.classList.contains('open')) {
        this.mobileSearchBtn.classList.remove('open');
      }

      this.mobileSearchIsOpen = false;
    }
  }

  openMobileUserAccMenu = () => {
    if (this.mobileUserAccBtn && !this.mobileUserAccBtn.classList.contains('open')) {
      this.mobileUserAccBtn.classList.add('open');
    }

    this.mobileUserAccIsOpen = true;
  }

  closeMobileUserAccMenu = () => {
    if (this.mobileUserAccBtn && this.mobileUserAccBtn.classList.contains('open')) {
      this.mobileUserAccBtn.classList.remove('open');
    }

    this.mobileUserAccIsOpen = false;
  }

  /**
   * Toggle display for search (desktop)
   * @param {object} event event data
   * @returns {void}
   */
  toggleSearchDisplay = event => {
    const { currentTarget } = event;
    if (!this.searchIsOpen) {
      this.openDektopSearch(currentTarget);
    } else {
      this.closeDektopSearch(currentTarget);
    }
  }

  /**
   * Open Dektop Search
   * @param {HTML Node} el
   */
  openDektopSearch = (el) => {
    this.showSearchContainer(el);
    el.setAttribute('aria-label', 'Close search');
    el.setAttribute('title', 'Close search');
    document.dispatchEvent(this.eventSearchOpen);
  }

  /**
   * Close Dektop Search
   * @param {HTML Node} el
   */
  closeDektopSearch = (el) => {
    this.closeSubNav();
    el.setAttribute('aria-label', 'Search');
    el.setAttribute('title', 'Search');
  }

  /**
   * Apply Events to all top level nav
   * @param {HTML Node} navEl
   * @returns {void}
   */
  enableNavEvents = navEl => {
    const topNavLink = navEl.querySelector('.top-nav-link');

    // hover and mobile touchstart event listener
    if ('ontouchstart' in document.documentElement) {
      navEl.addEventListener('touchstart', this.hoverNavItem);
    } else {
      navEl.addEventListener('mouseover', this.hoverNavItem);
      navEl.addEventListener('mouseover', () => {
        const snapshotWidget = this.navFullContainer.querySelector('sxm-ui-nav-account-presence-icons');
        if (snapshotWidget) {
          snapshotWidget.closePanels();
        }
      });
      navEl.addEventListener('click', this.hoverNavItem);
    }

    // What the nav item can do when it's on focus
    topNavLink.addEventListener('focus', this.topNavLinkFocusHandler);

    // Remove all of the things that were attached to the nav items when it's off focus (blur)
    topNavLink.addEventListener('blur', this.topNavLinkBlurHandler);
  }

  /**
   * Handler for menu items mouseover
   * @param {object} event event data
   * @returns {void}
   */
  hoverNavItem = event => {
    const { currentTarget } = event;

    if (!this.searchIsOpen) {
      this.openSubNavFor(currentTarget);
    }
  }

  /**
   * Open sub nav for specified top menu item
   * @param {HTML Node} el target top nav item
   * @return {void}
   */
  openSubNavFor = (el) => {
    let link = el.querySelector('.top-nav-link.has-more, .top-nav-link.has-self-drop');
    const isSearch = link && link.classList.contains('nav-search-link');

    if (window.innerWidth >= this.breakpointSize && !isSearch) {
      this.closeSubNav();

      if (link) {
        let dropnav;
        link.classList.add('underline-nav');

        if (detectMobile() === 'unknown') {
          link.classList.add('expanded');
          link.setAttribute('aria-expanded', 'true');
        }

        dropnav = document.querySelector(link.getAttribute('data-submenu'));// data-submenu

        if (dropnav) {
          dropnav.style.display = 'block';

          if (link.getAttribute('class').indexOf('has-more') >= 0) {
            const navRect = document.querySelector('.top-nav-link.expanded + ul li:last-child').getBoundingClientRect();
            const { bottom } = navRect;

            // Add 30px to equate 40px of bottom spacing
            this.navFullContainer.style.height = `${(bottom - this.getNavRect().top) + 30}px`;
          }
        }
      }
    }
  }

  /**
   * Handler for the keyboard events on focused nav items
   * @param {string} keypress code
   * @param {HTML Node}
   * @return {void}
   */
  keyControlsForNav = (code, el) => {
    let topLevelNavList;
    let currentIndex;
    let liParent = el.parentNode;

    switch (code) {
      case 40: // ArrowDown
      case 32: // Space

        if ( // check for nav items with drop down
          el.classList.contains('has-more')
          || el.classList.contains('has-self-drop')
          || el.hasAttribute('aria-haspopup')
        ) {
          // If nav item is search button, toggle search
          if (el.classList.contains('nav-search-link')) {
            if (!this.searchIsOpen) {
              this.openDektopSearch(el);
            } else {
              this.closeDektopSearch(el);
            }
          } else {
            // If any other nav button, show drop down
            // hoverNavItem.bind(liParent)();

            this.openSubNavFor(liParent);
            el.setAttribute('aria-expanded', 'true');
          }
          this.navFullContainer.classList.add('dark');
        }
        break;

      case 13: // Enter
        // Close search if opened
        if (el.classList.contains('nav-search-link')) {
          if (!this.searchIsOpen) {
            this.openDektopSearch(el);
          } else {
            this.closeDektopSearch(el);
          }
        } else if (el.getAttribute('data-submenu')) {
          let dropnavLink = el.nextElementSibling.querySelector('li.drop-nav-item.hide-medium-up a');

          // gets link from dropnav link
          if (
            dropnavLink
            && (!el.classList.contains('nav-search-link'))
          ) {
            window.location.href = dropnavLink.getAttribute('href');
          }
        } else {
          window.location.href = el.getAttribute('href');
        }

        break;

      case 38: // ArrowUp
        // Save this space for key up commands
        break;

      case 39: // ArrowRight
        topLevelNavList = [...this.navFullContainer.querySelectorAll('.top-nav-link')];
        currentIndex = topLevelNavList.indexOf(el);

        if (typeof topLevelNavList[currentIndex + 1] !== 'undefined') {
          topLevelNavList[currentIndex + 1].focus();
        } else {
          return;
        }
        break;

      case 37: // ArrowLeft
        topLevelNavList = [...this.navFullContainer.querySelectorAll('.top-nav-link')];
        currentIndex = topLevelNavList.indexOf(el);

        if (typeof topLevelNavList[currentIndex - 1] !== 'undefined') {
          topLevelNavList[currentIndex - 1].focus();
        } else {
          return;
        }
        break;

      default:
        return;
    }
  }

  /**
   * Handler for keyboard events for navigating through the main nav
   * @param {object} event event data
   * @returns {void}
   */
  keypressHandler = event => {
    const { keyCode, target } = event;

    // Stop browser defaults from interupting
    // the intended use for the following keys
    if (
      keyCode === 40 // ArrowDown
    || keyCode === 32 // Space
    || keyCode === 13 // Enter
    ) {
      event.preventDefault();
    }

    this.keyControlsForNav(keyCode, target);
  }

  /**
   * Event Handler for tabbed focused nav item
   * @param {object} event event data
   * @returns {void}
   */
  topNavLinkFocusHandler = event => {
    const navButton = event.target;
    let isNotMobile = detectMobile() === 'unknown';

    if (isNotMobile && !navButton.matches('.nav-search-link')) {
      this.closeSubNav();
    }

    // Added Keydown Event listener ONLY when the nav item is focused
    navButton.addEventListener('keydown', this.keypressHandler);
    navButton.addEventListener('keyup', this.disableFFKeyUp);
  }

  /**
   * Event Handler for tabbed burred nav item
   * @param {object} event event data
   * @returns {void}
   */
  topNavLinkBlurHandler = event => {
    const navButton = event.target;

    // Remove the Keydown Event Listener when the nav item is blurred
    navButton.removeEventListener('keydown', this.keypressHandler);
    navButton.removeEventListener('keyup', this.disableFFKeyUp);
  }

  /**
   * Show the container containing search
   * @param {HTML Node} link nav item that has search container
   * @returns {void}
   */
  showSearchContainer = (link) => {
    this.resetNavLinks();

    if (window.innerWidth >= this.breakpointSize && link) {
      let dropnav;
      this.searchIsOpen = true;

      link.classList.add('underline-nav');

      if (link) {
        if (detectMobile() === 'unknown') {
          link.classList.add('expanded');
          link.setAttribute('aria-expanded', 'true');
          this.navFullContainer.classList.add('dark');
        }

        dropnav = document.querySelector(link.getAttribute('data-submenu')); // data-submenu
        if (dropnav) {
          dropnav.style.display = 'block';

          if (link.getAttribute('class').indexOf('has-more') >= 0) {
            this.showPredictiveContainer();

            if (this.desktopSearchBar) {
              this.desktopSearchBar.focus();
            }
          }
        }
      } else {
        this.closeSubNav();
      }
    }
  }

  resizeSearch = () => {
    const predictiveList = document.getElementById('predictiveResultsDesktop');
    const lastListItem = predictiveList.querySelector('li:last-child');
    const rect = lastListItem.getBoundingClientRect();
    const { bottom } = rect;

    // Add 34px to equate 40px of bottom spacing
    this.navFullContainer.style.height = `${(bottom - this.getNavRect().top) + 34}px`;
  }

  showPredictiveContainer = () => {
    const searchRect = this.searchContainer.getBoundingClientRect();
    const { bottom } = searchRect;
    this.navFullContainer.style.height = `${bottom - this.getNavRect().top}px`;
  }

  resizePredictiveOnNav = () => {
    if (document.activeElement === this.desktopSearchInput) {
      this.resizeSearch();
    }
  }

  /**
   * Close / hide the sub nav
   * In it's most generic way
   */
  closeSubNav = () => {
    document.dispatchEvent(this.eventCloseSearchNav);
    this.resetContainerHeight();
    this.searchIsOpen = false;
    this.resetNavLinks();
  }

  /**
   * Reset Nav Container Height
   */
  resetContainerHeight = () => {
    this.navFullContainer.style.height = '';
  }

  /**
   * Mouse Leave handeler for
   * the nav items
   */
  mouseLeavehandler = () => {
    if (this.hasSearch) {
      const navSearchLink = this.navFullContainer.querySelector('.nav-search-link');

      // If the Search Container is not open
      if (!navSearchLink.classList.contains('expanded')) {
        this.closeSubNav();
      }
    } else {
      this.closeSubNav();
    }
  }

  /**
   * Close Sub menus by clicking off anywhere on the screen
   * @param {object} event
   * @returns {void}
   */
  documentCloseSubMenu = event => {
    const { target } = event;
    if (!this.navFullContainer.contains(target) && !window.location.href.includes('search')) {
      this.closeSubNav();
      if (this.mobileUserAccBtn) {
        this.mobileUserAccBtn.classList.remove('open');
      }
    }
  }

  /**
   * Close mobile nav event Handler
   * @param {object} event event
   * @returns {void}
   */
  closeMobileNav = event => {
    event.preventDefault();

    const openMenu = this.navFullContainer.querySelector('.top-nav-item.open');

    if (openMenu) {
      openMenu.classList.remove('open');
    }

    this.mainNav.classList.remove('open');
    this.parentContainer.style.zIndex = '5';
  }

  /**
   * Event Handler for Opening the Hamburger Menu
   * @param {object} event event data
   * @returns {void}
   */
  openMobileSliderMenu = event => {
    event.preventDefault();

    this.closeMobileSearch();
    this.closeMobileUserAccMenu();

    this.mainNav.classList.add('open');
    this.parentContainer.style.zIndex = '1000';
  }

  /**
   * Handler for main navigation
   * @param {object} event data
   * @return {void}
   */
  clickNavLink = event => {
    event.preventDefault();
    const { target, currentTarget } = event;

    // if (window.innerWidth <= this.breakpointSize) {
    if (window.innerWidth < this.breakpointSize) {
      if (currentTarget.parentNode.className.indexOf('open') === -1) {
        currentTarget.parentNode.classList.add('open');
      } else {
        currentTarget.parentNode.classList.remove('open');
      }
    } else {
      let dropnavLink = null;

      if (target.nextElementSibling) {
        dropnavLink = target.nextElementSibling.querySelector('li.drop-nav-item.hide-medium-up a') || null;
      }

      // gets link from dropnav link
      if (
        dropnavLink
        // target.classList.contains('expanded') &&
        && (!target.classList.contains('nav-search-link'))
      ) {
        window.location.href = dropnavLink.getAttribute('href');
      }
      target.classList.add('expanded');
    }
  }

  /**
   * Remove links and reset attributes to
   * to links in the top level nav so they're
   * back to their default state
   */
  resetNavLinks = () => {
    this.topNavLinks.forEach(l => {
      l.classList.remove('underline-nav');

      if (
        l.classList.contains('has-more')
        || l.classList.contains('has-self-drop')
        || l.hasAttribute('aria-haspopup')
      ) {
        l.setAttribute('aria-expanded', 'false');
      }
      l.classList.remove('expanded');
    }); // remove underline
    for (let dropNav of this.dropNavs) {
      dropNav.style.display = '';
    }
    this.navFullContainer.classList.remove('dark');
  }

  /**
   * Disable firefox firing a click event on keypress
   * for 'enter' and 'space bar'
   * @param {object} event data
   * @return {void}
   */
  disableFFKeyUp = event => {
    event.preventDefault();
  }

  /**
   * Window Resize Event Handler
   * For the nav
   */
  navWindowResizeHandler = debounce(() => {
    if (window.innerWidth >= this.breakpointSize) {
      const openedMobileNav = document.querySelector('.top-nav-item.open');

      if (openedMobileNav) {
        openedMobileNav.classList.remove('open');
        this.mainNav.classList.remove('open');
      }
    } else {
      this.closeSubNav();
    }
  }, 100, false);
}
