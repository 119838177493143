// This file is strictly for nav standalone files used in widgets

import '@babel/polyfill';

// Pollfills
import './pollyfills/closest';
import './pollyfills/customEvent';

// Globals
import icons from './globals/icons';

// Components
import Accordion from './components/accordion';
import NavFull from './components/NavFull';
import NavSnapshot from './components/NavSnapshot';
import Footer from './components/footer';
import SearchBar from './components/SearchBar';

(function () {
  function documentReady(fn) {
    if (document.readyState !== 'loading') {
      fn();
    } else if (document.addEventListener) {
      document.addEventListener('DOMContentLoaded', fn);
    } else {
      document.attachEvent('onreadystatechange', () => {
        if (document.readyState !== 'loading') {
          fn();
        }
      });
    }
  }

  documentReady(() => {
    icons.loadVar();

    const accordians = document.querySelectorAll('[data-accordion-trigger]');

    if (accordians) {
      accordians.forEach((acc) => {
        const accordion = new Accordion(acc);
      });
    }

    const searchBarEls = document.querySelectorAll('.predictive-search');
    if (searchBarEls) {
      searchBarEls.forEach((searchBarEl) => {
        searchBarEl._sxm = {};
        searchBarEl._sxm.SearchBar = new SearchBar(searchBarEl);
      });
    }

    const navEl = document.querySelector('.sxm-full-nav-container #sxmFullNav');
    if (navEl) {
      const nav = new NavFull(navEl);
    }
    
    const snapshotEl = document.querySelector('sxm-ui-nav-account-presence-icons');
    if (snapshotEl) {
      const snapshot = new NavSnapshot(snapshotEl);
    }

    const footerEl = document.querySelector('.full-footer, .simple-footer');
    if (footerEl) {
      const footer = new Footer(footerEl);
    }
  });
}());
