export default function (breakpoint, onlyNumeric) {
  const breakpoints = {
    mobilesmall: '320px',
    mobilemedium: '375px',
    mobilelarge: '414px',
    small: '540px',
    medium: '768px',
    large: '1024px',
    xlarge: '1336px',
	navtemp: '1115px',
  };

  const numericBreakpoints = {
    mobilesmall: '320',
    mobilemedium: '375',
    mobilelarge: '414',
    small: '540',
    medium: '768',
    large: '1024',
    xlarge: '1336',
	navtemp: '1115px',
  };

  if (onlyNumeric) {
    return numericBreakpoints[breakpoint];
  }

  return breakpoints[breakpoint];
}
