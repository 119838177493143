import ComponentState from '../globals/ComponentState';

/* eslint-disable max-len */
// HTML template
// <button
//    class="component--card-link component--accordion-title"
//    data-accordion-trigger data-open="{ITEM_ID}"
//    data-group="{GROUP_NAME}"
//    expanded-text="{EXPANDED_TEXT}"
//    collapsed-text="{COLLAPSED_TEXT}"
//    tabindex="0">{ITEM TITLE}
//  </button>
//  <div class="component--accordion-content" id="{ITEM_ID}" aria-hidden="true">
//  <div class="accordion-content-container">
//    {ACCORDION CONTENT GOES HERE}
//   </div>
//  </div>
// </div>

// button attributes
// trigger: data-accordion-trigger
// class: component--accordion-title
// data-open: points to id of content to show
// data-group (optional): the accordion group (behaves like radio button group, only one selection allowed among members), omit for no group
// collapsed-text (optional): the button text for when the accordion is collapsed
// expanded-text (optional): the button text for when the accordion is expanded
// tab-index: 0 to allow tabbing in the order as it appears in the DOM

// content attributes
// class: component--accordion-content
// id: match the associated button attribute "data-open"
// aria-hidden: true by default

// notes:
// It is a good idea to include a container div within the content.  This container is there to allow a padding on the content.
// Without it any padding on the content itself will result in a jerky transition animation

export default class Accordion {
  constructor(acc) {
    this.groupObj = {};
    this.accordion = acc;
    this.id = acc.getAttribute('id');
    this.accContent = document.querySelector(`#${this.accordion.getAttribute('data-open')}`);
    this.state = new ComponentState(acc, acc.getAttribute('aria-expanded') === 'true');
    this.isGrid = this.accContent.classList.contains('plan-comparison-inner-grid');

    const expanded = this.state.get();
    if (expanded) {
      this.expand(false);
    } else {
      this.collapse(false);
    }

    // if the element has a data group, add element to group object
    if (this.accordion.hasAttribute('data-group')) {
      let dataGroup = this.accordion.getAttribute('data-group');

      if (this.groupObj[dataGroup]) {
        // data group array has already been created, add it
        this.groupObj[dataGroup].push(this.accordion);
      } else {
        // add new array into group object with data group as property name
        this.groupObj[dataGroup] = [this.accordion];
      }
    }
    this.accordion.addEventListener('click', this.toggleAccodion);


    if (this.id) {
      const hash = window.location.hash.toLowerCase().replace('#', '');

      if (hash === this.id.toLowerCase()) {
        [...document.querySelectorAll('[data-accordion-trigger].active')].forEach((trigger) => {
          trigger._sxm.Accordion.collapse(true);
        });
        this.expand(true);
        window.scrollTo(0, acc.getBoundingClientRect().top);
      }
    }
  }

  expand(animated) {
    this.accordion.classList.add('active');
    this.accContent.setAttribute('aria-hidden', false);
    this.expandContent(this.accContent, animated);
    if (!this.isGrid) {
      this.state.set(true);
    }
  }

  collapse(animated) {
    this.accordion.classList.remove('active');
    this.accContent.setAttribute('aria-hidden', true);
    if (animated) {
      this.collapseContent(this.accContent);
    }
    if (!this.isGrid) {
      this.state.set(false);
    }
  }

  toggleAccodion = (event) => {
    // get the active status of the element
    let wasActive = this.accordion.classList.contains('active');

    // checking if element has data-group and closing group if so
    if (this.accordion.hasAttribute('data-group')) {
      this.closeGroup(this.accordion.getAttribute('data-group'));
    }

    // toggling active class
    if (wasActive) {
      this.collapse(true);
    } else {
      this.expand(true);
    }
  };

  closeGroup = (group) => {
    if (this.groupObj[group]) {
      this.groupObj[group].forEach((el) => {
        let elContent = document.querySelector(`#${el.getAttribute('data-open')}`);

        if (elContent.classList.contains('active')) {
          this.collapseContent(elContent);

          if (el.hasAttribute('collapsed-text')) {
            el.innerHTML = el.getAttribute('collapsed-text');
          }
        }
        el.classList.remove('active');
      });
    }
  };

  expandContent = (elContent, animated) => {
    const height = elContent.scrollHeight;

    elContent.style.height = `${height}px`;
    elContent.classList.add('active');

    this.removeTransitionListeners(elContent);

    if (animated) {
      elContent.addEventListener('transitionend', this.contentExpanded);
    } else {
      this.contentExpanded(elContent);
    }

    if (this.isGrid) {
      elContent.style.overflow = 'visible';
    }
  };

  contentExpanded = (el) => {
    let elContent = el.currentTarget ? el.currentTarget : el;
    let elTitle = elContent.parentNode.querySelector('.component--accordion-title');

    elContent.style.height = null;
    elTitle.setAttribute('aria-expanded', true);
    elContent.removeEventListener('transitionend', this.contentExpanded);
  };

  contentCollapsed = (evt) => {
    let elContent = evt.currentTarget;
    let elTitle = elContent.parentNode.querySelector('.component--accordion-title');

    elContent.setAttribute('aria-hidden', true);
    elTitle.setAttribute('aria-expanded', false);
    elContent.removeEventListener('transitionend', this.contentCollapsed);

    if (this.isGrid) {
      elContent.style.overflow = 'hidden';
    }
  };

  collapseContent = (elContent) => {
    const height = elContent.scrollHeight;

    requestAnimationFrame(() => {
      // set height inline so transition is not using height: auto
      elContent.style.height = `${height}px`;

      requestAnimationFrame(() => {
        // removes height:auto and inline content height so it defaults to style (likely height:0)
        elContent.classList.remove('active');
        elContent.style.height = null;
      });
    });

    this.removeTransitionListeners(elContent);
    elContent.addEventListener('transitionend', this.contentCollapsed);
  };

  removeTransitionListeners = (el) => {
    el.removeEventListener('transitionend', this.contentCollapsed);
    el.removeEventListener('transitionend', this.contentExpanded);
  };
}
