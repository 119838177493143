import Utils from '../globals/Utils';
import predictiveParams from '../configs/search-predictive-params.json';

export default class SearchBar {
  constructor(searchBar) {
    this.query = decodeURIComponent(Utils.getUrlParam('q') || '').replace(/\+/g, ' ').replace(/</g, '').trim();
    this.input = searchBar.querySelector('input[type=search]');
    this.results = searchBar.querySelector('.predictive-results');
    this.clearBtn = searchBar.querySelector('.search__clearbtn');
    this.uniqueResults = [];
    this.eventPopulated = new CustomEvent('predictive-results-populated');
    this.lastQuery = '';
    this.navFullContainer = document.querySelector('#sxmFullNav');
    this.desktopSearch = null;
    this.navHeightNoResults = null;
    document.addEventListener('search-opened', () => {
      if (!searchBar.classList.contains('mobile')) {
        this.desktopSearch = searchBar;
      }
      if (this.desktopSearch && this.input.value.length === 0) {
        const searchRect = this.desktopSearch.getBoundingClientRect();
        const { bottom } = searchRect;
        this.navHeightNoResults = bottom;
      }
    });
    document.addEventListener('search-closed', () => {
      this.clearBar();
    });

    predictiveParams.engine_key = 'zXSk2M4i_2mJch-PAtpZ'; // production engine
    const devDomains = ['devcms-author.corp.siriusxm.com', 'localhost', '127.0.0.1'];
    if ((devDomains.indexOf(window.location.hostname) > -1) && (window.location.search.indexOf('engine=prod') === -1)) {
      predictiveParams.engine_key = '_ntjsQzWxEcW9xBYrH8z'; // dev engine
    }

    this.input.value = this.query;

    this.input.addEventListener('keyup', (e) => {
      this.onKeyup(e);
    });
    this.clearBtn.addEventListener('click', () => {
      this.clearBar();
    });

    // handling empty search field
    searchBar.querySelector('form').addEventListener('submit', (e) => {
      if (this.input.value.trim() ? false : true) {
        e.preventDefault();
      }
    });
  }

  addResult(result) {
    result = result.trim();
    const tempDiv = document.createElement('div');

    if (this.uniqueResults.indexOf(result) === -1) {
      this.uniqueResults.push(result);
      tempDiv.innerHTML = result;

      const domain = Utils.getDomain('prod', 'dev', 'uat', 'prod') || 'https://www.siriusxm.com';
      this.results.insertAdjacentHTML('beforeEnd',
        `<li class="autocomplete-item"><a href="${domain}/search?q=${tempDiv.innerText}&predictive=true" class="h4 autocomplete-label">${result}</a></li>`);
      this.resultsHeight = this.results.offsetHeight;
    }
  }

  focus() {
    // focus function
    this.input.focus();
  }

  clearBar() {
    this.input.value = '';
    this.clearResults();
  }

  clearResults() {
    this.results.innerHTML = '';
    this.uniqueResults = [];
    this.hidePredictiveContainer(this.resultsHeight);
  }

  async getPredictive(query) {
    if (query !== this.lastQuery) { // prevent request when keys like "Shift" are pressed
      this.lastQuery = query;
      predictiveParams.q = query.replace(/\+/g, ' ');
      const queryString = Utils.jsonToQuery(predictiveParams);
      const xhttp = await Utils.httpGet(`https://www.siriusxm.com/predictivesearch?${queryString}`);
      const results = JSON.parse(xhttp.response).records.page;

      this.clearResults();

      results.forEach((result) => {
        if (result.highlight.artistsyouhear) {
          const artists = result.highlight.artistsyouhear.replace(/(\[|\])/g, '').split(',');

          for (let i = 0; i < artists.length; i += 1) {
            if (artists[i].indexOf('<em>') >= 0) {
              this.addResult(artists[i]);
              return;
            }
          }
        } else if (result.highlight.contestname) {
          this.addResult(result.highlight.contestname);
          return;
        } else if (result.displayname) {
          this.addResult(result.displayname);
          return;
        }

        if (result.genre) {
          this.addResult(result.genre);
          return;
        }

        if (result.chCategory) {
          this.addResult(result.chCategory);
          return;
        }

        this.addResult(result.title.replace(' | SiriusXM', '').trim());
      });

      const domain = Utils.getDomain('prod', 'dev', 'uat', 'prod') || 'https://www.siriusxm.com';
      this.results.insertAdjacentHTML('beforeEnd',
        `<li class="autocomplete-item"><div class="h4"><a id="all_result" href="${domain}/search?q=${query}&predictive=true" class="autocomplete-label bold-link">All Results</a></div></li>`);

      document.dispatchEvent(this.eventPopulated);
    }
  }

  hidePredictiveContainer = (resultsHeight) => {
    if (resultsHeight > 0) {
      this.navFullContainer.style.height = `${this.navHeightNoResults}px`;
    }
  }

  onKeyup() {
    this.input.value = this.input.value.replace(/</g, '');
    if (this.input.value.length >= 3) {
      this.getPredictive(this.input.value);
    } else if (this.input.value.length === 0) {
      // search query is cleared
      this.clearResults();
      this.hidePredictiveContainer(this.resultsHeight);
    }
  }
}
