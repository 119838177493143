import svgSprite from './spritesheet';

const iconsConfig = {
  car: {
    class: 'icon-content',
    size: '',
    href: 'icon-car',
  },
  streaming: {
    class: 'icon-content',
    size: '',
    href: 'icon-streaming',
  },
  home: {
    class: 'icon-content',
    size: '',
    href: 'icon-home',
  },
  'apple-badge': {
    class: 'icon-content',
    size: '',
    href: 'icon-apple-badge',
  },
  pandora: {
    class: 'icon-content',
    size: '',
    href: 'icon-custom-stations',
  },
  'pandora-basic': {
    class: 'icon-content',
    size: '',
    href: 'icon-custom-stations',
  },
  'dropdown-sm': {
    class: 'icon-utility',
    size: 'small',
    href: 'icon-dropdown-sm',
  },
  expand: {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-expand',
  },
  collapse: {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-collapse',
  },
  play: {
    class: 'icon-content',
    size: '',
    href: 'icon-play',
  },
  'checkmark-lg': {
    class: 'icon-content',
    size: '',
    href: 'icon-checkmark-lg',
  },
  'check-mark': {
    class: 'icon-utility',
    size: '',
    href: 'icon-check-mark-flex-stroke',
  },
  'dash-mark': {
    class: 'icon-utility',
    size: '',
    href: 'icon-dash',
  },
  'x-mark': {
    class: 'icon-utility',
    size: '',
    href: 'icon-x-mark-flex-stroke',
  },
  'features-all': {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-features-all',
  },
  'features-some': {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-features-some',
  },
  'dropdown-lg': {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-dropdown-lg',
  },
  'menu-kebab': {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-menu-kebab',
  },
  'menu-burger': {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-menu-burger',
  },
  validate: {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-validate',
  },
  lock: {
    class: 'icon-content',
    size: '',
    href: 'icon-lock',
  },
  'checkmark-sm': {
    class: 'icon-utility',
    size: 'small',
    href: 'icon-checkmark-sm',
  },
  'pagination-selected': {
    class: 'icon-utility',
    size: 'small',
    href: 'icon-pagination-selected',
  },
  pipe: {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-pipe',
  },
  add: {
    class: 'icon-utility',
    size: 'small',
    href: 'icon-add',
  },
  remove: {
    class: 'icon-utility',
    size: 'small',
    href: 'icon-remove',
  },
  'cc-amex': {
    class: 'icon-content',
    size: '',
    href: 'icon-cc-amex',
  },
  'cc-dci': {
    class: 'icon-content',
    size: '',
    href: 'icon-cc-dci',
  },
  'cc-discover': {
    class: 'icon-content',
    size: '',
    href: 'icon-cc-discover',
  },
  'cc-jcb': {
    class: 'icon-content',
    size: '',
    href: 'icon-cc-jcb',
  },
  'cc-mc': {
    class: 'icon-content',
    size: '',
    href: 'icon-cc-mc',
  },
  'cc-visa': {
    class: 'icon-content',
    size: '',
    href: 'icon-cc-visa',
  },
  'cc-unionpay': {
    class: 'icon-content',
    size: '',
    href: 'icon-cc-unionpay',
  },
  'hp-pause': {
    class: 'icon-content',
    size: '',
    href: 'icon-hp-pause',
  },
  'hp-play': {
    class: 'icon-content',
    size: '',
    href: 'icon-hp-play',
  },
  facebook: {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-facebook',
  },
  twitter: {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-twitter',
  },
  instagram: {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-instagram',
  },
  youtube: {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-youtube',
  },
  tiktok: {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-tiktok',
  },
  'app-download': {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-app-download',
  },
  'smart-phone': {
    class: 'icon-utility',
    size: '',
    href: 'icon-smart-phone',
  },
  'tool-tip': {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-tool-tip',
  },
  'custom-stations': {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-custom-stations',
  },
  'pandora-premium': {
    class: 'icon-content',
    size: 'large',
    href: 'icon-pandora-premium',
  },
  at: {
    class: 'icon-content',
    size: '',
    href: 'icon-at',
  },
  'user-profile': {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-user-profile',
  },
  extended: {
    class: 'icon-content',
    size: '',
    href: 'icon-extended',
  },
  'basic-forecast': {
    class: 'icon-content',
    size: '',
    href: 'icon-basic-forecast',
  },
  buoy: {
    class: 'icon-content',
    size: '',
    href: 'icon-buoy',
  },
  cloudechotops: {
    class: 'icon-content',
    size: '',
    href: 'icon-cloudechotops',
  },
  'hi-res-wind': {
    class: 'icon-content',
    size: '',
    href: 'icon-hi-res-wind',
  },
  'high-res-surface': {
    class: 'icon-content',
    size: '',
    href: 'icon-high-res-surface',
  },
  radar: {
    class: 'icon-content',
    size: '',
    href: 'icon-basic-forecast',
  },
  'app-access': {
    class: 'icon-content',
    size: '',
    href: 'icon-app-access',
  },
  'surface-temps': {
    class: 'icon-content',
    size: '',
    href: 'icon-surface-temps',
  },
  taf: {
    class: 'icon-content',
    size: '',
    href: 'icon-taf',
  },
  'turbulence-icing': {
    class: 'icon-content',
    size: '',
    href: 'icon-turbulence-icing',
  },
  'wind-waves': {
    class: 'icon-content',
    size: '',
    href: 'icon-wind-waves',
  },
  warning: {
    class: 'icon-utility',
    size: '',
    href: 'icon-warning',
  },
  audioPauseButton: {
    class: 'icon-content',
    size: '',
    href: 'icon-audio-pause-button',
  },
  audioPlayButton: {
    class: 'icon-content',
    size: '',
    href: 'icon-audio-play-button',
  },
  audioReplayButton: {
    class: 'icon-content',
    size: '',
    href: 'icon-audio-replay-button',
  },
  vipPerks: {
    class: 'icon-content',
    size: '',
    href: 'icon-vip-perks',
  },
  adFree: {
    class: 'icon-content',
    size: '',
    href: 'icon-ad-free',
  },
  businessChannels: {
    class: 'icon-content',
    size: '',
    href: 'icon-business-channels',
  },
  sxmApp: {
    class: 'icon-content',
    size: '',
    href: 'icon-sxm-app',
  },
  talkMicrophone: {
    class: 'icon-content',
    size: '',
    href: 'icon-talk-microphone',
  },
  videoCollections: {
    class: 'icon-content',
    size: '',
    href: 'icon-video-collections',
  },
  additionalSubscriptions: {
    class: 'icon-content',
    size: '',
    href: 'icon-additional-subscriptions',
  },
  aviation: {
    class: 'icon-content',
    size: '',
    href: 'icon-aviation',
  },
  billingSummary: {
    class: 'icon-content',
    size: '',
    href: 'icon-billing-summary',
  },
  inactiveRadio: {
    class: 'icon-content',
    size: '',
    href: 'icon-inactive-radio',
  },
  marine: {
    class: 'icon-content',
    size: '',
    href: 'icon-marine',
  },
  helpBillingMyAccount: {
    class: 'icon-content',
    size: '',
    href: 'icon-helpBillingMyAccount',
  },
  helpFAQs: {
    class: 'icon-content',
    size: '',
    href: 'icon-helpFaqs',
  },
  helpMySubs: {
    class: 'icon-content',
    size: '',
    href: 'icon-helpMySubs',
  },
  helpProblems: {
    class: 'icon-content',
    size: '',
    href: 'icon-helpProblems',
  },
  tag: {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-tag',
  },
  tagMusic: {
    class: 'icon-utility',
    size: 'large',
    href: 'icon-tag-music',
  },
  'next-arrow-right': {
    class: 'icon-utility',
    size: '',
    href: 'next-arrow-right',
  },
  'circle-checkbox': {
    class: 'icon-utility',
    size: '',
    href: 'circle-checkbox',
  },
};

const icons = {
  append: (iconsObj) => {
    if (iconsObj !== 0) {
      // append icons
      let iconAppend = function (icon, iconName) {
        let iconHolder = document.createElement('div');

        iconHolder.innerHTML = `
          <svg class="icon ${iconsConfig[iconName].class} ${iconsConfig[iconName].size}">
            <use class="icon-${iconName}" xlink:href="#${iconsConfig[iconName].href}"></use>
          </svg>
        `;
        iconHolder.classList.add(`icon-${iconName}-wrapper`);
        icon.appendChild(iconHolder);
      };

      // get icons
      iconsObj.forEach((icon) => {
        let iconName = icon.getAttribute('data-icon').split(',');

        iconName.forEach((iconSingle) => {
          if (iconsConfig[iconSingle]) {
            iconAppend(icon, iconSingle);
          }
        });
      });
    }
  },
  load: () => {
    fetch(`${scriptFolder}svg/icons.svg`)
      .then(response => response.text())
      .then(svg => {
        let div = document.createElement('div');
        div.classList.add('sxm-icon-spritesheet');
        div.setAttribute('aria-hidden', 'true');
        div.innerHTML = svg;
        document.body.insertBefore(div, document.body.childNodes[0]);

        // getting group to initially load.
        let iconsAdd = [...document.querySelectorAll('[data-icon]')];
        icons.append(iconsAdd);
      });
  },
  loadVar: () => {
    let div = document.createElement('div');
    div.classList.add('sxm-icon-spritesheet');
    div.setAttribute('aria-hidden', 'true');
    div.innerHTML = svgSprite;
    document.body.insertBefore(div, document.body.childNodes[0]);

    // getting group to initially load.
    let iconsAdd = [...document.querySelectorAll('[data-icon]')];
    icons.append(iconsAdd);
  },
};

export default icons;
