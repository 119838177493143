import detectMobile from '../globals/detectMobile';
import getbreakpoint from '../globals/breakpoints';
import debounce from '../globals/debounce';

class Footer {
  largeBreakpoint = `${getbreakpoint('medium', true) - 1}px`;

  isMobile = window.matchMedia(`(max-width: ${this.largeBreakpoint})`).matches;

  appLinks = {
    desktopUrl: 'https://www.siriusxm.com/streaming',
    appleUrl: 'https://apps.apple.com/us/app/the-siriusxm-app-try-it-out/id317951436',
    googleUrl: 'https://play.google.com/store/apps/details?id=com.sirius&hl=en&gl=US',
  }

  constructor(footer) {
    this.footerYear = footer.querySelector('span.year') || footer.querySelector('span.year');

    this.footerYear.innerHTML = new Date().getFullYear();
    this.handleFooterUI(footer, footer.innerHTML);
    this.handleRedirects(footer);
    window.addEventListener('resize', debounce(() => this.handleResize(footer), 100));
  }

  handleRedirects = footer => {
    const device = detectMobile();
    const { desktopUrl, appleUrl, googleUrl } = this.appLinks;

    if (device !== 'unknown' && window.matchMedia(`(max-width: ${this.largeBreakpoint})`).matches) {
      // Make markup adjustments to download app UI based on device
      // If mobile display an android badge if device is Android, (defaults to Apple)
      // data-mobile-only
      // data-desktop-only
      const badge = footer.querySelectorAll('.download-badge li');

      badge.forEach(
        ( element ) => {
          if ( element && element.classList.contains('show-on-mobile') ) {
            if (device === 'Android') {
              element.innerHTML = `<a href=${googleUrl} class="footer-group-listen--button footer-group-listen--google" aria-label="Get the SXM Android app from the Play Store">Google Play</a>`;
            } else if (device === 'iOS') {
              element.innerHTML = `<a href=${appleUrl} class="footer-group-listen--button footer-group-listen--apple" aria-label="Get the SXM iOS app from the App Store">Apple Store</a>`;
            }

            element.closest('.footer-group-listen').style.display = 'block';
          }
          else {
            element.closest('.footer-group-listen').style.display = 'none';
          }
        },
      );
    } else {
      // If not on a mobile phone, display inline Download text
      const badgeContainer = [...footer.querySelectorAll('.download-badge li')];

      badgeContainer.forEach(( badge ) => {
        if ( badge.hasAttribute('data-desktop-only') ) {
          badge.innerHTML = `
            <a href=${googleUrl} class="footer-group-listen--button footer-group-listen--google" style="margin-right:16px" aria-label="Get the SXM Android app from the Play Store">Google Play</a>
            <a href=${appleUrl} class="footer-group-listen--button footer-group-listen--apple" aria-label="Get the SXM iOS app from the App Store">Apple Store</a>
          `;
          badge.closest('.footer-group-listen').style.display = 'block';
        } else {
          badge.closest('.footer-group-listen').style.display = 'none';
        }
      });
    }
  }

  handleFooterUI = (footer, resizing) => {
    this.isMobile = window.matchMedia(`(max-width: ${this.largeBreakpoint})`).matches;

    if (this.isMobile) {
      const accordionHeadings = [...document.querySelectorAll('.full-footer--content-row [data-accordion-trigger]')];
      const accordionContainers = [...document.querySelectorAll('.accordion-content-container')];

      // When resizing the browser and going from tablet to mobile
      // Add the accordion related markup
      if (resizing && accordionContainers.length === 0) {
        const allAccordionContent = [...footer.querySelectorAll('.full-footer--accordion-content')];

        accordionHeadings.forEach((heading, idx) => {
          heading.classList.add('component--accordion-title');
          heading.setAttribute('data-icon', 'dropdown-sm"');
          heading.setAttribute('tabindex', '0');
          const chevronIcon = `
            <div class="icon-dropdown-sm-wrapper mn">
                <svg class="icon icon-utility small">
                <use class="icon-dropdown-sm" href="#icon-dropdown-sm"></use>
                </svg>
            </div>
          `;

          heading.innerHTML += chevronIcon;

          allAccordionContent[idx].classList.add('component--accordion-content');

          const accordionContentContainer = allAccordionContent[idx].querySelector('div');
          accordionContentContainer.classList.add('accordion-content-container');
        });
      }
    } else {
      // remove accordian related elements when not on a mobile sized screen
      const allAccordionContent = [...footer.querySelectorAll('.component--accordion-content')];
      const accordionContainers = [...footer.querySelectorAll('.accordion-content-container')];
      const headings = [...footer.querySelectorAll('.full-footer--content-row-content-column-heading')];

      allAccordionContent.forEach((accordionContent, idx) => {
        accordionContent.classList.remove('component--accordion-content');
        accordionContent.removeAttribute('aria-hidden');
        accordionContainers[idx].classList.remove('accordion-content-container');

        headings[idx].classList.remove('component--accordion-title');
        headings[idx].removeAttribute('data-icon');
        const headingText = headings[idx].textContent;
        headings[idx].innerHTML = headingText;
        headings[idx].removeAttribute('tabindex');
      });
    }
  }

  handleResize = (footer) => {
    this.handleFooterUI(footer, true);
    this.handleRedirects(footer);
  }
}

export default Footer;
