import getBreakpoint from '../globals/breakpoints';
import debounce from '../globals/debounce';

export default class NavSnapshot {
  constructor(widget) {
    this.widget = widget;
    this.nav = document.querySelector('.sxm-full-nav-container #sxmFullNav');
    this.desktopNav = document.querySelector('.snapshot-widget-li');
    this.mobileNav = document.querySelector('.mobile-secondary-nav');
    
    document.addEventListener('click', (e) => { this.closeWidget(e); });
    window.addEventListener('resize', debounce(() => { this.onResize(); }, 10, false));
    window.addEventListener('load', () => { this.onResize(); });
    
    widget.addEventListener('mousedown', () => { this.closeNav(); });
    widget.addEventListener('keydown', this.escapeHandler);
  }
    
  closeWidget(e) {
    if (this.widget && this.widget.closePanels) {
      if (!e.target.matches('sxm-ui-nav-account-presence-icons') && !e.target.closest('sxm-ui-nav-account-presence-icons')) {
        this.widget.closePanels();
      }
    }
  }
  
  closeNav() {
    this.nav._sxm.NavFull.closeSubNav();
    this.nav._sxm.NavFull.closeMobileSearch();
  }
  
  onResize() {
    const isDesktop = window.matchMedia(`(min-width: ${getBreakpoint('navtemp')})`).matches;
    
    if (isDesktop) {
      if (this.desktopNav) {
        if (!this.desktopNav.querySelector('sxm-ui-nav-account-presence-icons')) {
          this.desktopNav.appendChild(this.widget);
        }
      }
    } else if (this.mobileNav) {
      if (!this.mobileNav.querySelector('sxm-ui-nav-account-presence-icons')) {
        this.mobileNav.appendChild(this.widget);
      }
    }
  }

  /**
   * Close opened widget on escape
   * @param {object} event 
   */
  escapeHandler = (event) => {
    const { keyCode } = event;

    if (keyCode === 27) {
      if (this.widget && this.widget.closePanels) {
        this.widget.closePanels();
      }
    }
  }
}
