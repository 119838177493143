/* eslint-disable class-methods-use-this */

/**
 * A helper class with common methods that are used in multiple classes
 */
class Utils {
  /**
   * Determine if an element is within the view
   * @param {HTMLElement} element The element to test
   */
  isInView(element) {
    // To check if an element is visible ensure that:
    // 1) The top of the element is less than the height of the
    // window (window.innerHeight changes on scroll)
    // 2) The bottom of the element isn't hidden above the beginning/top
    // of the window (i.e it's been scrolled passed)
    // 3) The element hasn't been set to display none or hidden
    // 4) The element isn't inside of a parent that's set to display none
    // which is the case when getBoundingClientRect()'s width and height returns as 0

    const boundedElement = element.getBoundingClientRect();
    const {
      height, bottom, width, top,
    } = boundedElement;

    return ((top <= (window.innerHeight || document.documentElement.clientHeight)
      && bottom >= 0)
      && getComputedStyle(element).display !== 'none'
      && getComputedStyle(element).visibility !== 'hidden'
      && ( (width === 0 && height > 0) || (width > 0 && height === 0)
      || (width > 0 && height > 0) ));
  }

  /**
   * Executes a callback function when the document is ready
   * @param {Function} fn The callback function
   */
  documentReady(fn) {
    if (document.readyState !== 'loading') {
      fn();
    } else if (document.addEventListener) {
      document.addEventListener('DOMContentLoaded', fn);
    } else {
      document.attachEvent('onreadystatechange', () => {
        if (document.readyState !== 'loading') {
          fn();
        } });
    }
  }

  /**
   * Waits for the _sxm property to be initialized on an element.
   * @param {HTMLElement} element The element to watch
   * @param {String} objId The ID of the object to wait for. This is defined in
   * the map in sxm.phoenix.js
   * @return Returns a promise which resolves with the element's object.
   */
  waitForSxm(element, objId) {
    let passCount = 0;
    const maxPasses = 10;

    return new Promise((resolve, reject) => {
      const waitInterval = setInterval(() => {
        if (!element || (passCount >= maxPasses)) {
          clearInterval(waitInterval);
          return false;
        }

        if (element._sxm && element._sxm[objId]) {
          clearInterval(waitInterval);
          resolve(element._sxm[objId]);
        }

        passCount += 1;
      }, 10);
    });
  }

  /**
   * Simple HTTP get helper.
   * @param {String} url The URL to fetch
   * @return Returns a promise which resolves with the response.
   */
  httpGet(url) {
    return new Promise((resolve, reject) => {
      const xhttp = new XMLHttpRequest();

      xhttp.onreadystatechange = () => {
        if (xhttp.readyState === 4) {
          if (xhttp.status === 200) {
            resolve(xhttp);
          } else if ((xhttp.status >= 400) && (xhttp.status <= 599)) {
            reject(xhttp);
          }
        }
      };

      xhttp.open('GET', url, true);
      xhttp.send();
    });
  }

  /**
   * Handles logic for determing the appropriate domain to use. This method
   * has three parameters to override the default behavior of each environment.
   * @param {string} local Override which domain localhost will point to.
   * The default points to the live site.
   * @param {string} dev Override which domain the dev environment will point
   * to. The default points to the dev environment.
   * @param {string} uat Override which domain the uat environment will point
   * to. The default points to the uat environment.
   * @return {string} The domain you should use.
   */
  getDomain(local = 'live', dev = 'relative', uat = 'relative', prod = 'relative') {
    const domains = {
      dev: 'https://devcms-author.corp.siriusxm.com',
      uat: 'https://uatcms-author.corp.siriusxm.com',
      uatd: 'https://uatwww.siriusxm.com',
      prod: 'https://cms-author.corp.siriusxm.com',
      live: 'https://www.siriusxm.com',
      wcs11g: 'http://mgmtwcs.corp.siriusxm.com',
      relative: '',
    };

    let domain = domains.relative;

    if (/(localhost|127\.0\.0\.1)/gi.test(window.location.hostname)) {
      domain = domains[local];
    } else if (/(uatcms.siriusxm)/gi.test(window.location.hostname)) {
      domain = domains.uatd;
    } else if (/(www)/gi.test(window.location.hostname)) {
      domain = domains.relative;
    } else if (/(dev|dv)/gi.test(window.location.hostname)) {
      domain = domains[dev];
    } else if (/(uat|qa)/gi.test(window.location.hostname)) {
      domain = domains[uat];
    } else if (/(cms)/gi.test(window.location.hostname)) {
      domain = domains[prod];
    }

    return domain;
  }

  /**
   * Fires an event on a target element.
   * @param {HTMLElement} target The target element where the event will trigger
   * @param {String} eventName The name of the event
   */
  fireEvent(target, eventName) {
    let e = null;

    if (document.createEventObject) {
      e = document.createEventObject();
      target.fireEvent(`on${eventName}`, e);
    } else {
      e = document.createEvent('HTMLEvents');
      e.initEvent(eventName, true, true);
      target.dispatchEvent(e);
    }
  }
  
  getUrlParam(param) {
    if (window.location.search.indexOf(`${param}=`) > -1) {
      const params = window.location.search.replace('?', '').split('&');
          
      for (let i = 0; i < params.length; i += 1) {
        if (params[i].indexOf(`${param}=`) === 0) {
          return params[i].split('=')[1];
        }
      }
    }
      
    return undefined;
  }
  
  objectToQuery(key1, obj) {
    const query = [];
    Object.keys(obj).forEach((key2) => {
      const value = obj[key2];
      const key = `${key1}[${key2}]`;
          
      if (Array.isArray(value)) {
        query.push(this.arrayToQuery(key, value));
      } else if (typeof value === 'object') {
        query.push(this.objectToQuery(key, value));
      } else {
        query.push(this.valueToQuery(key, value));
      }
    });
    return query.join('&');
  }
  
  arrayToQuery(key, arr) {
    const query = [];
    key = `${key}[]`;
    arr.forEach((value) => {
      if (Array.isArray(value)) {
        query.push(this.arrayToQuery(key, value));
      } else if (typeof value === 'object') {
        query.push(this.objectToQuery(key, value));
      } else {
        query.push(this.valueToQuery(key, value));
      }
    });
    return query.join('&');
  }
  
  valueToQuery(key, value) {
    return `${encodeURIComponent(key) }=${ encodeURIComponent(value)}`;
  }
  
  jsonToQuery(json) {
    const query = [];
    Object.keys(json).forEach((key) => {
      const value = json[key];
          
      if (Array.isArray(value)) {
        query.push(this.arrayToQuery(key, value));
      } else if (typeof value === 'object') {
        query.push(this.objectToQuery(key, value));
      } else {
        query.push(this.valueToQuery(key, value));
      }
    });
    return query.join('&');
  }

  /**
   * Gathers a list of focusable elements within the passed in element 
   * (including the element itself).
   * @param {Element} element
   * @param {Boolean} includeNegativeTabIndex - if true, includes elements with negative tabindex
   */
  getFocusableElements(element, includeNegativeTabIndex = false) {
    if (!element) {
      console.error('getFocusableElements: element does not exist');
    }
    const focusableElementsSelector = 'a[href], button, input, textarea, select, details, [tabindex], [contenteditable]';
    const focusableElements = [];
  
    // if the container element itself is focusable, add it to the list first
    if (element.matches(focusableElementsSelector)) {
      focusableElements.push(element);
    }
    focusableElements.push(...element.querySelectorAll(focusableElementsSelector));
  
    // filter by relevant tabindex, styling, and disabled attributes
    return focusableElements.filter((el) => {
      if (!includeNegativeTabIndex && el.hasAttribute('tabindex') && (el.getAttribute('tabindex') < 0)) {
        return false;
      }
      if (el.hasAttribute('disabled') || el.hasAttribute('hidden')) {
        return false;
      }
      return true;
    });
  }
}

export default new Utils();

/* eslint-enable class-methods-use-this */
